<script setup>
const { t, locale } = useI18n();
const localePath = useLocalePath();

const route = useRoute();

const ticketLink = computed(() =>
  locale.value === "cs"
    ? "https://goout.net/cs/dox-centrum-soucasneho-umeni/vzhi/"
    : "https://goout.net/en/dox-centre-for-contemporary-art/vzhi/"
);

const menuItems = computed(() => [
  { link: "programme", text: t("menu.programme"), external: false },
  // { link: "guests", text: t("menu.guests"), external: false },
  { link: "practical", text: t("menu.practical"), external: false },
  { link: "about", text: t("menu.about"), external: false },
  {
    link: ticketLink.value,
    text: t("menu.tickets"),
    external: true,
  },
]);
</script>

<template>
  <nav class="menu flex" aria-label="Main Navigation">
    <div class="menu__logo">
      <NuxtLink :to="localePath('index')">
        <img src="/images/stars/main.svg" alt="Logo" />
      </NuxtLink>
    </div>
    <nav class="menu__items desktop flex">
      <li v-for="(item, index) in menuItems" :key="index">
        <div :class="{ active: localePath(item.link) === route.path }">
          <LinkExternal :item="item" v-if="item.external" />
          <NuxtLink v-else :to="localePath(item.link)">
            {{ item.text }}
          </NuxtLink>
        </div>
      </li>
    </nav>
    <LanguageSwitch />

    <HamburgerOverlay :menuItems="menuItems" />
  </nav>
</template>

<style scoped>
.menu {
  width: 100%;
  color: white;
  justify-content: space-between;
  padding: 2vw;
  position: absolute;
  box-sizing: border-box;
  z-index: 2;
  align-items: center;
  @media (max-width: 799px) {
    padding: 4vw;
  }
}

.menu__logo {
  width: 3rem;
  z-index: 4;
  img {
    object-fit: contain;
    width: 100%;
  }
}

.menu__items li,
.language {
  text-transform: uppercase;
  letter-spacing: 0.8px;
  a {
    text-decoration: none;
    position: relative;
    &:hover {
      transition-duration: 350ms;
    }
    &:hover::after {
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 1px;
      bottom: -1px;
      left: 0;
      background-color: currentColor;
      transition: width 0.3s ease-out;
    }
    &:hover::after {
      width: 100%;
    }
  }
  .active,
  .active.a,
  .active a {
    font-weight: bold;
    cursor: default;
    &::after {
      content: none;
    }
  }
}

.menu__items li {
  padding: 0 2vw;
}
</style>
