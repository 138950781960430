<script setup>
const { t } = useI18n();
const spoluorganizatori = {
  title: t("footer.co-organisers"),
  items: [{ title: "dox.png", link: "https://www.dox.cz/", width: "60%" }],
};
const hlavni_partneri = {
  title: t("footer.main-partners"),
  items: [
    {
      title: "s_fond.svg",
      link: "https://fondkinematografie.cz/",
      width: "70%",
    },
    {
      title: "ministerstvo_kultury.png",
      link: "https://www.mkcr.cz/",
      width: "90%",
    },
    {
      title: "visegrad_fund.png",
      link: "https://www.visegradfund.org/",
      width: "80%",
    },
  ],
};
const part_spon = {
  title: t("footer.partners-sponsors"),
  items: [
    { title: "iodf.png", link: "https://dokweb.net/en", width: "90%" },
    {
      title: "instit_fr.png",
      link: "https://www.ifp.cz/cz/homepage#/",
      width: "90%",
    },
    {
      title: "japaneese.svg",
      link: "https://www.roc-taiwan.org/cz_cs/index.html",
    },
    {
      title: "korea_embassy.svg",
      link: "https://overseas.mofa.go.kr/cz-cs/index.do",
    },
    { title: "cedczm.svg", link: "https://www.kreativnievropa.cz/" },
    { title: "praha.png", link: "https://praha.eu/web/praha/", width: "60%" },
    { title: "lancraft.png", link: "https://www.lancraft.cz/" },
    { title: "brainz.png", link: "https://immersive.cz/", width: "70%" },
    {
      title: "zip-scene.png",
      link: "https://conf.zip-scene.com/",
      width: "80%",
    },
    {
      title: "numoteq.svg",
      link: "https://www.numoteq.com/cs",
      width: "90%",
    },
    {
      title: "nl.svg",
      link: "https://www.welcome-to-nl.nl/",
      width: "90%",
    },
    {
      title: "polsky_in.svg",
      link: "https://instytutpolski.pl/praha/",
      width: "50%",
    },
    {
      title: "flanders.svg",
      link: "https://visiteurope.com/en/experience/flanders-a-state-of-the-arts/",
      width: "90%",
    },
  ],
};
const part_dox = {
  title: t("footer.dox-partners"),
  items: [
    { title: "praha.png", link: "https://praha.eu/web/praha/", width: "50%" },
    { title: "abakus.svg", link: "https://www.abakus.cz/", width: "50%" },
    {
      title: "metrostav.svg",
      link: "https://www.metrostav.cz/cs",
      width: "90%",
    },
    { title: "ministerstvo_kultury.png", link: "https://www.mkcr.cz/" },
    { title: "hosp_noviny.svg", link: "https://hn.cz/" },
    { title: "respekt.png", link: "https://www.respekt.cz/", width: "80%" },
    { title: "art_antiques.svg", link: "https://www.artantiques.cz/" },
  ],
};
const part_visegrad = {
  title: t("footer.visegrad-partners"),
  items: [
    { title: "iodf.png", link: "https://dokweb.net/en", width: "60%" },
    { title: "make_dox.svg", link: "https://makedox.mk/mk/en/", width: "60%" },
    { title: "anibar.svg", link: "https://anibar.org/" },
    { title: "slovak_gda.svg", link: "https://sgda.sk/", width: "40%" },
    { title: "fadaib.svg", link: "https://fdu.bg.ac.rs/en", width: "60%" },
    { title: "digi_ba.svg", link: "https://digi.ba/", width: "60%" },
    { title: "vn_lab.svg", link: "https://vnlab.org/en/", width: "40%" },
  ],
};
</script>

<template>
  <footer class="bg-black partners">
    <div class="mw-70">
      <div class="grid m-bottom-4">
        <div class="w-33 w-m-100">
          <h4>{{ spoluorganizatori.title }}</h4>
          <a
            :href="spoluorganizatori.items[0].link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              :src="`/images/logo/${spoluorganizatori.items[0].title}`"
              :style="{ maxWidth: '12rem' }"
            />
          </a>
        </div>
        <div class="w-66 w-m-100">
          <h4>{{ hlavni_partneri.title }}</h4>
          <div class="grid partners-grid">
            <div class="w-33" v-for="partner in hlavni_partneri.items">
              <a :href="partner.link" target="_blank" rel="noopener noreferrer">
                <img
                  :src="`/images/logo/${partner.title}`"
                  :style="{ maxWidth: partner.width }"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <h4>{{ part_spon.title }}</h4>
      <div class="grid partners-grid m-bottom-4">
        <div class="w-16 w-m-25 w-s-33" v-for="partner in part_spon.items">
          <a :href="partner.link" target="_blank" rel="noopener noreferrer">
            <img
              :src="`/images/logo/${partner.title}`"
              :style="{ maxWidth: partner.width }"
          /></a>
        </div>
      </div>
      <h4>{{ part_dox.title }}</h4>
      <div class="grid partners-grid m-bottom-4">
        <div class="w-16 w-s-33" v-for="partner in part_dox.items">
          <a :href="partner.link" target="_blank" rel="noopener noreferrer">
            <img
              :src="`/images/logo/${partner.title}`"
              :style="{ maxWidth: partner.width }"
          /></a>
        </div>
      </div>
      <h4 class="m-bottom-2">{{ part_visegrad.title }}</h4>
      <div class="grid partners-grid m-bottom-4">
        <div
          class="partner-item w-16 w-s-33"
          v-for="partner in part_visegrad.items"
        >
          <a :href="partner.link" target="_blank" rel="noopener noreferrer">
            <img :src="`/images/logo/${partner.title}`"
          /></a>
        </div>
      </div>
      <FooterLinks />
    </div>
  </footer>
</template>

<style>
.partners {
  .line {
    width: 100%;
    background-color: white;
    height: 1px;
    margin: 4rem 0;
  }
  .partner-item {
    padding: 2vw;
  }
  .partners-grid {
    align-items: center;
    .w-16 {
      text-align: center;
    }
    img {
      max-height: 8vw;
    }
  }
}
</style>
